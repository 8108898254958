/* navbar */
nav.navbar {
  width: 100%;
  top: 0;
  z-index: 99;
}

.fixed_navbar {
  position: fixed;
  transition: 2s ease-in-out;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgb(255 255 255) !important;
}

nav .navbar-brand {
  width: 15%;
  height: auto;
}

nav .d-flex {
  width: 40%;
  padding: 8px 0px;
}

nav .input-group .btn {
  border: none;
  padding: 0;
  width: 70px;
  height: 100%;
  font-size: 12px;
}

nav .input-group .btn:hover {
  background-color: transparent !important;
  border-radius: 10px;
}

nav .input-group input {
  line-height: 2;
  border-radius: 10px;
}

nav .input-group input::placeholder {
  color: rgb(170, 170, 170);
}

nav .input-group .input-group-text {
  /* background-color: #ffb91f; */
  color: black;
  border-radius: 10px;
  padding: 0;
}

.navSideActions a:hover {
  color: #ffb91f;
}

.nav_contact {
  display: flex;
}

.custom_navbar_dropdown .dropdown-menu {
  width: 218px;
  text-align: left;
}

.custom_navbar_dropdown .btn {
  background-color: #ffb91f;
  color: black;
  padding: 10px 35px;
  border: none;
  font-weight: bold;
  border-radius: 20px;
}

.custom_navbar_dropdown .btn:hover {
  background-color: black;
  color: white;
}

.custom_navbar_dropdown a {
  color: #686868;
}

.custom_navbar_dropdown a:hover {
  color: #ffb91f;
}

@media (min-width: 576px) {
  .navSideActions {
    display: flex;
  }

  .navSideActions a {
    margin: 0 15px;
  }
}

.custom_container {
  padding: 20px 50px;
  max-width: 100%;
}

/* sidebar */

.sidebar {
  text-align: left;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar .nav-item {
  border-bottom: 1px solid rgb(181, 181, 181);
}

.sidebar .active {
  background-color: #b0b0b0;
}

.sidebar .nav-item a {
  color: black;
  font-weight: 500;
  padding: 15px;
}

.sidebar .nav-item a:hover {
  color: #ffb91f;
}

.toggle_siderbar {
  display: none;
}

/* banner */

.categories_carousel {
  min-height: 10vh;
  /* color: white; */
  background-color: rgb(244 244 244);
  /* added this part when modifying dropdown with scroll */
  overflow-x: hidden;
}

.categories_carousel_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* added this part when modifying dropdown with scroll */
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 3px;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  white-space: nowrap;
}

.categories_carousel_div a {
  color: #535353;
}

.categories_carousel_div a:hover {
  color: #ffb91f;
}

.categories_carousel_div .dropdown-menu {
  max-height: 50vh;
  overflow-y: scroll;
  width: fit-content;
  scrollbar-width: thin;
}

.categories_carousel_div .dropdown-menu-2 {
  background-color: white;
  max-height: 50vh;
  overflow-y: scroll;
  width: fit-content;
  scrollbar-width: thin;
  position: absolute;
  z-index: 9;
  display: none;
  flex-direction: column;
  text-align: left;
}

.categories_carousel_div .dropdown-menu-2 .dropdown-item-2 {
  color: #575757;
  padding: 10px 5px;
}
.categories_carousel_div .dropdown-menu-2 .dropdown-item-2:hover {
  color: #ffb91f;
}

.image_overlay {
  filter: contrast(0.35);
}

.main_carousel .carousel-item {
  max-height: 82vh;
}

.main_carousel .carousel-item .carousel-caption {
  bottom: 10rem;
}

.main_carousel .carousel-item .carousel-caption .btn {
  background-color: #ffffff;
  color: black;
  border: none;
  padding: 12px 25px;
  border-radius: 33px;
  font-size: small;
  font-weight: bold;
}

.main_carousel .carousel-item .carousel-caption .btn:hover {
  background-color: #ffb91f;
  color: black;
  border: none;
}

.main_carousel .carousel-item img {
  transform: translate(0, -30%);
}

.react-multi-carousel-item a {
  color: white;
}

.react-multi-carousel-item a:hover {
  color: #ffb91f;
}

.carousel-category-item {
  padding: 0 10px;
}

.carousel-category-item img {
  height: 70px;
  width: auto;
}

.carousel-category-item h5 {
  font-size: 11px;
}
/* quote_row */

.quote_row {
  padding: 2% 0;
  background-color: #f8fcff;
  margin: 2% 0;
}

.quote_row p {
  color: #4b4848;
  font-size: 13px;
}

.quote_row cite {
  font-size: 11px;
}

/* home categories container */
.category_container {
  text-align: left;
  padding: 10px;
  margin: 10px 0;
  overflow: hidden;
  padding-bottom: 30px;
}

.category_container a {
  color: black;
}

.category_container a:hover {
  color: #ffb91f;
}

.category_container .react-multi-carousel-list {
  overflow: visible;
  height: 65vh;
}

.category_container .react-multi-carousel-track {
  margin-top: -50px;
}

/* product container */
/* .product_container {
  border-right: 1px solid rgb(246, 246, 246);
} */

.product_container .product_container_content {
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.product_container a {
  color: black;
}

.product_container a:hover {
  color: #ffb91f;
}

.product_container:hover {
  position: relative;
  z-index: 4;
  box-shadow: 0 7px 10px rgba(101, 101, 101, 0.16);
}

.product_container .prod_image_container {
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.product_container .prod_image {
  /* width: 100%; */
  height: auto;
}

/* .product_container p {
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1; */
/* margin-bottom: 1px; */
/* display: inline; 
} */

.product_container h5 {
  color: black;
  font-size: medium;
  font-weight: bold;
}

.product_container .product_container_btn {
  /* display: none; */
  /* position: absolute; */
  /* left: 0;
  right: 0;
  top: 100%; */
  /* padding: 5px 20px 20px; */
  margin: 0;
  z-index: 8;
  /* background-color: white; */
}

.product_container .product_container_btn .btn {
  background-color: black;
  border: none;
  color: white;

  padding: 10px 0px;
  width: 100%;
}

.product_container .product_container_btn .row {
  padding: 10px 0;
}

.product_container .product_container_btn .row .col {
  width: 30%;
  text-align: center;
}

.product_container .product_container_btn .row .btn {
  background-color: transparent;
  border: none;
  padding: 0;
}

/* .product_container:hover .product_container_btn {
  display: block;
  box-shadow: 0 7px 10px rgba(101, 101, 101, 0.4);
} */

.product_container .btn:hover {
  background-color: #ffb91f;
  color: black;
}

.percantage_off {
  background-color: red;
  color: white;
  font-weight: 700;
  font-size: small;
  padding: 6px;
  border-radius: 20px;
  width: fit-content;
  /* position: absolute; */
  /* z-index: 10; */
}

.product_container .product_left_actions {
  position: absolute;
  display: grid;
}

.product_container .product_left_actions .btn {
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
}

/* .product_container .product_left_actions svg {
  background-color: #ffffffeb; 
} */

.product_container .product_extended_actions {
  background-color: transparent;
  position: relative;
  /* display: none; */
}

/* .product_container:hover .product_extended_actions {
  display: block;
} */

.product_container .product_extended_actions > * {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

/* .product_container .product_extended_actions a {
  position: absolute;
  right: 0;
} */

.product_container .product_extended_actions .btn {
  padding: 0;
  background-color: transparent;
  border: none;
}

.off_price {
  text-decoration: line-through;
  font-size: 12px;
  color: grey;
  margin: 0px 4px;
  text-decoration-color: red;
}

/* quick view */

.quick_view {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #000000ab;
  cursor: pointer;
  display: none !important;
  height: 100%;
  left: 0;
  outline: none !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.quick_view_show {
  display: block !important;
}

.quick_view_spec {
  background: #fff;
  box-shadow: 0 0 8px #0009;
  display: block;
  height: 85% !important;
  left: 4% !important;
  position: absolute;
  top: 10% !important;
  width: 92% !important;
  overflow: auto;
}

.quick_view_close {
  position: absolute;
  right: 10px;
  top: 5px;
  color: white;
  cursor: pointer;
  z-index: 99999;
}

/* featured */
.featured_products {
  height: 70vh;
  margin: 35px 0px;
}

.featured_products .container {
  max-width: 100%;
  padding: 0px 35px;
}

.featured_header {
  padding: 75px 20px;
  text-align: left;
  background-color: #ffb91f;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.featured_header .btn {
  padding: 10px 26px;
  color: black;
  border: 1px solid;
  background-color: transparent;
  border-radius: 30px;
}

.featured_header .btn:hover {
  color: white;
  background-color: black;
  border: none;
}

.featured_content {
  display: flex;
}

.featured_content .product_container {
  height: 100%;
  width: 33%;
  text-align: left;
}

/* popular products */

.popular_products {
  margin: 35px 0px;
  text-align: left;
}

.popular_products .container {
  padding: 0px 35px;
}

.popular_content {
  display: contents;
}

.popular_content .product_container {
  height: 100%;
  width: 33%;
  text-align: left;
  /* border: 1px solid rgb(201 201 201); */
  margin: 1px;
}

.popular_content .product_container img {
  /* width: auto; */
  /* width: 100%; */
  height: 120%;
  /* height: 230px; */
}

.popular_content .loading-card {
  width: 32%;
}

/* what clients say */

.what_clients_say {
  background: linear-gradient(
      0deg,
      rgba(19, 16, 18, 0.53),
      rgba(26, 22, 24, 0.654)
    ),
    url("assets/images/happy_customer_b.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  min-height: 80vh;
  margin: 20px;
  border-radius: 20px;
  padding: 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.what_clients_say h1 {
  font-weight: bold;
}

.what_clients_say_carousel {
  width: 75%;
  padding: 10px;
  align-self: center;
  background-color: #2f2f2f96;
}

.what_clients_say blockquote p {
  font-size: 13px;
}

.what_clients_say .blockquote-footer {
  font-size: 12px;
  color: #cbcbcb;
}

/* deals of the week */

.deals_of_week {
  margin: 20px;
}

.deals_of_week .deals_header {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.deals_of_week h1 {
  font-weight: bold;
  font-size: 25px;
}

.deals_of_week .deals_time {
  display: flex;
  /* align-items: baseline; */
  font-size: 24px;
  padding: 10px;
  background-color: darkorange;
  border-radius: 10px;
}

.deals_of_week .deals_time .deals_time_box {
  margin: 0 10px;
}

.deals_of_week .deals_time .deals_time_box h2 {
  font-size: 17px;
}

.deals_of_week .deals_time .deals_time_box h5 {
  font-size: 14px;
  margin-bottom: 0;
}

.deal_week .product_container {
  border: none;
  margin: 0 15px;
  width: 30%;
}

.deal_week .percantage_off {
  background-color: red;
}

.deal_week .product_container_btn .btn {
  background-color: transparent;
  color: black;
  border: 1px solid grey;
}

/* best sellers */

.best_sellers {
  text-align: left;
}

.best_sellers .best_seller_image {
  background: linear-gradient(
      0deg,
      rgba(19, 16, 18, 0.57),
      rgba(26, 22, 24, 0.694)
    ),
    url("assets/images/image13.jpg");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  min-height: 70vh;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.best_seller_image h2 {
  font-weight: bold;
  color: aqua;
}

/* second product container */

.second_product_container {
  display: flex;
  height: 180px;
}

.second_product_container .percantage_off {
  background-color: red;
  position: absolute;
}

.second_product_container .second_prod_image_container {
  height: 100%;
  display: flex;
  justify-content: center;
}

.second_product_container .second_prod_image_container .second_prod_image {
  height: 100%;
  width: auto;
}

.second_prod_details {
  padding: 10px;
}

.second_prod_details h5 {
  font-size: 14px;
  font-weight: bold;
}

.second_prod_details a {
  color: black;
}

.second_prod_details .off_price {
  font-size: 11px;
}

.second_prod_details .btn {
  background-color: darkorange;
  border: none;
  color: black;
}

.second_product_extended_actions {
  position: relative;
}

.second_product_extended_actions_div {
  position: absolute;
  right: 0;
  flex-direction: column;
  display: none;
}

.second_product_container:hover .second_product_extended_actions_div {
  display: flex;
}

.second_product_extended_actions_div > * {
  margin-bottom: 7px;
}

.second_product_extended_actions_div .btn {
  background: transparent;
  padding: 0;
  border: none;
}

/* filter div */

.toggleShowFilter {
  display: none;
}

.closeFilter {
  display: none;
}

.filter-list .itemsList {
  margin-top: 15px;
  max-height: 30vh;
  overflow: auto;
}

.listTile {
  margin: 5px;
  display: inline-block;
  font-weight: 300;
}

.listTile input {
  height: 15px;
  margin-right: 10px;
  width: 15px;
}

/* range slider */

.range-slider {
  height: 5px;
  position: relative;
  background-color: #e1e9f6;
  border-radius: 2px;
}
.range-selected {
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background-color: #000000;
}

.range .range-slider-inputs {
  position: relative;
}

.range .range-slider-inputs input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -7px;
  background: none;
  pointer-events: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.range-slider-inputs input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #000000;
  background-color: #fff;
  pointer-events: auto;
  -webkit-appearance: none;
}
.range-slider-inputs input::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid #000000;
  background-color: #fff;
  pointer-events: auto;
  -moz-appearance: none;
}

.range .range-price-input {
  display: flex;
  margin: 25px 0 10px 0;
  justify-content: space-between;
}

.range .range-price-input input {
  padding: 5px;
  width: 40%;
  outline: none;
  border-radius: 10px;
  border: 1px solid;
  font-weight: 300;
}

.range button {
  margin: 15px;
  width: 30%;
  border: none;
  background-color: #ffb91f;
  border-radius: 20px;
  color: black;
  font-weight: 500;
}

.range button:hover {
  background-color: black;
  color: white;
}

/* product page */

.product_page .product_page_image .carousel-indicators {
  margin-bottom: 0;
  overflow-y: hidden;
}

.product_page .product_page_image .carousel-indicators [data-bs-target] {
  width: 70px;
  height: 60px;
  /* border: 1px solid #b0b0b0; */
  border-radius: 10px;
  background-color: transparent;
}

.product_page .product_page_image .percantage_off {
  z-index: 10;
}

.product_page .product_page_image .product_image_indicators {
  display: flex;
  justify-content: center;
}

.product_page .product_page_image .product_image_indicators .image_indicator {
  margin: 2px;
  border: 1px solid darkgrey;
  border-radius: 12px;
  width: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3px;
  cursor: pointer;
}

.product_page
  .product_page_image
  .product_image_indicators
  .image_indicator
  img {
  width: 90%;
}

.product_page .product_page_details {
  text-align: left;
}

.product_page .product_page_details h1 {
  font-size: 2rem;
}

.product_page .product_page_details a {
  color: black;
}

.product_page .product_page_details a:hover {
  color: #ffb91f;
}

.product_page .product_page_details .product_page_details_buttons .btn {
  padding: 12px 50px;
  margin: 10px 5px;
  border-radius: 8px;
  font-weight: bold;
}

.add_to_cart {
  background-color: transparent;
  color: black;
  border: 1px solid #c7c7c7;
}

.add_to_cart:hover {
  background-color: #000000;
  color: white;
}

.add_to_cart:disabled {
  background-color: #a2a2a2;
  color: black;
}

.buy_now {
  background-color: transparent;
  color: black;
  border: 1px solid rgb(216, 216, 216);
}

.buy_now:disabled {
  background-color: transparent;
  color: black;
  border: 1px solid rgb(95, 95, 95);
}

.buy_now:hover {
  background-color: #000000;
  color: white;
}

.p_re {
  color: gray;
  font-size: 15px;
}

.add_all_to_cart {
  padding: 12px 50px;
  margin: 10px 5px;
  border-radius: 9px;
  border: none;
  font-weight: bold;
  background-color: #000000;
  color: #ffb91f;
}

.add_all_to_cart:hover {
  background-color: #ffb91f;
  color: #000000;
}

/* related products */

.related_products {
  text-align: left;
  padding: 10px;
  margin: 10px 0;
  overflow: hidden;
  padding-bottom: 30px;
}

.related_products a {
  color: black;
}

.related_products a:hover {
  color: #ffb91f;
}

.related_products .react-multi-carousel-list {
  overflow: visible;
  height: 72vh;
}

.related_products .product_container .product_container_btn .btn {
  background-color: transparent;
  color: darkorange;
  border: 1px solid grey;
}

/* .related_products .react-multi-carousel-track {
  margin-top: -70px; 
} */
/* grouped products */
.grouped_products {
  text-align: left;
  margin: 30px 0;
}

.grouped_products .grouped_product_container {
  padding: 10px;
  width: 27%;
}

.grouped_products a {
  color: #000000;
}

.grouped_products a:hover {
  color: #ffb91f;
}

.grouped_products h5 {
  font-weight: 400;
  font-size: 15px;
}

.grouped_products_actions {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* product more info */

.product_page_more_info {
  min-height: 30vh;
  margin: 20px 0;
}

.product_page_more_info .nav-tabs {
  font-size: 22px;
  font-weight: 500;
}

.product_page_more_info .nav-tabs .nav-link {
  color: #828282;
}

.product_page_more_info .nav-tabs .nav-link.active {
  color: #ffb91f;
}

.product_page_more_info .tab-content {
  text-align: left;
}

.product_key_features {
  margin-top: 10px;
  color: #575757;
}

.product_key_features li {
  margin: 5px 0;
}

/* cart page */
.cartPage th {
  font-weight: 300;
}

.shopping_cart_list {
  overflow: auto;
}

.shopping_cart_list .cart_min_display {
  display: none;
}

.cart_container {
  font-weight: 300;
  text-align: left;
}

.cart_container img {
  width: 150px;
}

.cart_container td {
  vertical-align: middle;
}

.cart_container svg {
  cursor: pointer;
}

.cart_container svg:hover {
  color: #ffb91f;
}

.cart_container_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart_container_name p {
  font-weight: 400;
}

.cart_container_name a {
  color: #787878;
}

.cart_container_name a:hover {
  color: #ffb91f;
}

.cart_checkout {
  text-align: left;
  padding: 20px;
  border: 2px solid #ffb81f5e;
  height: fit-content;
  border-radius: 10px;
  justify-content: end;
}

.cart_checkout p {
  display: flex;
  justify-content: space-between;
}

.cart_checkout .btn {
  width: 90%;
  border: none;
  border-radius: 25px;
  background-color: #ffb91f;
  color: black;
  font-weight: bold;
  padding: 0;
}

.cart_checkout .btn a {
  color: black;
  padding: 15px 0;
  display: block;
  width: 100%;
}

.cart_checkout .btn:hover {
  background-color: #000000;
  color: white;
}

.cart_checkout .btn a:hover {
  color: white;
}

.empty_cart {
  font-weight: bold;
  padding: 15px 65px;
  background-color: transparent;
  color: #787878;
  border: 1px solid;
  border-radius: 30px;
}

.empty_cart:hover {
  background-color: #ffb91f;
  color: black;
  border: none;
}

/* checkout */

.checkout_page h4 a {
  color: grey;
}

.checkout_page h4 a:hover {
  color: #ffb91f;
}

.order_checkout {
  text-align: left;
  padding: 20px;
  border: 2px solid #ffb81f5e;
  height: fit-content;
  border-radius: 10px;
  justify-content: end;
}

.order_checkout p {
  display: flex;
  justify-content: space-between;
}

.order_checkout .btn {
  width: 90%;
  border: none;
  padding: 15px 0;
  border-radius: 25px;
  background-color: #ffb91f;
  color: black;
  font-weight: bold;
}

.order_checkout .btn:hover {
  background-color: #000000;
  color: white;
}

.order_checkout .card {
  margin-top: 7px;
  margin-left: 25px;
  background-color: #f7fbff;
  color: #585858;
}

.billing_details {
  text-align: left;
}

/* orders page */
.orders_page {
  min-height: 80vh;
}

.order_item {
  text-align: left;
  background: white;
  position: relative;
  margin-top: 25px;
}

.order_item:before {
  content: "";
  width: 96%;
  height: 13px;
  position: absolute;
  top: -13px;
  left: 11px;
  background-position: -3px -5px, 0 0;
  background-image: radial-gradient(farthest-side, transparent 5px, #f7f7f7 0);
  background-size: 15px 15px;
}
.order_item:after {
  content: "";
  width: 96%;
  height: 13px;
  position: absolute;
  bottom: 0px;
  left: 10px;
  background-position: -3px 2px, 0 0;
  background-image: radial-gradient(farthest-side, transparent 5px, #f7f7f7 0);
  background-size: 15px 15px;
}

/* auth page */
.authpage {
  padding: 20px;
  min-height: 100vh;
}

.authpage .authform {
  text-align: left;
  padding: 20px;
  background-color: #fbfbfb;
  border-radius: 10px;
}

.authpage .authform > * {
  margin: 20px 7px;
}

.authpage .authform h2 {
  font-weight: 700;
}

.authpage .authform .form-control {
  line-height: 2.3rem;
}

.authpage .authform .btn {
  display: block;
  width: 80%;
  margin: auto;
  margin-top: 15px;
  padding: 11px 0px;
  font-size: 20px;
  background: #ffb91f;
  border: none;
  color: black;
  border-radius: 8px;
  font-weight: 600;
}

.authpage .authform .btn:hover {
  background-color: black;
  color: white;
}

.brandcomponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brandcomponent h4 {
  color: #0b7584;
}

/* admin page */
.adminPage {
  min-height: 100vh;
}

.admin_content_header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.admin_content_header .btn {
  border-radius: 18px;
  box-shadow: 3px 4px 6px silver;
}

.admin_content_content {
  margin-top: 15px;
  text-align: justify;
}

.admin_content_content .table tbody tr {
  cursor: pointer;
}

.admin_content_content .table tbody svg {
  color: #686868;
  cursor: pointer;
}

.admin_content_content .table tbody svg:hover {
  color: #ffb91f;
}

.adminPage form .mb-3 {
  /* display: flex;
  justify-content: space-between; */
  text-align: left;
}

/* .adminPage form .mb-3 .form-control {
  width: 80%;
} */

.adminPage form .mb-3 .toggle_list_div {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  color: var(--bs-body-color);
  background-clip: padding-box;
  position: relative;
  z-index: 5;
}

.adminPage form .mb-3 .form-svg-display {
  height: 50px;
  width: auto;
  /* background-color: #dbdbbf; */
}

.adminPage form .form-image-display {
  width: fit-content;
  display: inline-block;
}

.adminPage form .form-image-display img {
  height: 150px;
  width: auto;
}

.adminPage form .mb-3 .toggle_list_btn {
  margin: 9px 1px;
  float: left;
  border-radius: 12px;
}

.adminPage form .mb-3 .toggle_list_btn:hover {
  background-color: var(--bs-btn-hover-bg) !important;
}

.adminPage form .mb-3 .dropdown {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  color: var(--bs-body-color);
  background-clip: padding-box;
}

.adminPage form .mb-3 .dropdown .dropdown-toggle {
  display: block;
  width: 75%;
  background-color: #00000021;
  color: #1d1d1d;
  border: 1px solid grey;
  margin: 5px 0;
}

.adminPage form .mb-3 .dropdown .dropdown-menu {
  width: 80%;
  margin-top: 5px;
  max-height: 200px;
  overflow: scroll;
}

.drop-down-menu-custom {
  display: none;
  max-height: 200px;
  overflow-y: scroll;
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.drop-down-item-custom {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.drop-down-item-custom:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.drop-down-item-custom:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.adminPage form .mb-3 .form-control-in-div {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.form-control-in-div li {
  cursor: pointer;
  color: #686868;
  padding: 3px;
}

.form-control-in-div li:hover {
  color: #ffb91f;
}

.adminPage form .mb-3 .two-inputs {
  display: flex;
  justify-content: space-between;
}
.adminPage form .mb-3 .two-inputs .mb-3 {
  display: flex;
}

.adminPage form .mb-3 .two-inputs .form-control {
  width: 70%;
}

.adminPage form .mb-3 .two-inputs .btn {
  margin-left: 4px;
  height: 37px;
}

.adminPage form .mb-3 .form-control-in-div .input-group {
  width: 75%;
}

.adminPage form .mb-3 .form-control-in-div .input-group input {
  width: 70%;
}

.adminPage form .mb-3 .form-control-in-div .input-group .input-group-text {
  padding: 0;
  background-color: transparent;
  margin-left: 2px !important;
}
.adminPage form .form-submit-btn {
  width: 50%;
}

.adminPage .admin_order_form {
  text-align: left;
}

.adminPage .admin_content_content .dropdown .btn {
  background-color: transparent;
  border: none;
}

.adminPage .admin_content_content .dropdown .dropdown-toggle::after {
  display: none;
}
/* profile */

.profile {
  min-height: 75vh;
  text-align: left;
}

.profile .update_btn_div {
  width: 100%;
  display: flex;
  justify-content: center;
}
/* about us */

.about-us {
  text-align: left;
  padding-top: 50px;
}

/* footer */
.footer {
  text-align: left;
  font-weight: 500;
  padding: 20px;
  padding-bottom: 0;
  margin-top: 50px;
  background-color: #ededed;
}

.footer .map {
  margin-bottom: 30px;
  padding: 0;
}

.footer .top_part {
  border-bottom: 1px solid #cfcfcf;
  padding: 20px;
}

.footer .top_part p {
  color: #8f8f8f;
}

.footer .top_part .col-sm-4 p {
  margin-bottom: 0;
}

.footer .top_part ul {
  padding: 0 !important;
  list-style: none;
}

.footer .top_part ul li {
  margin: 10px 0px;
}

.footer .top_part a {
  color: #6f6f6f;
  font-weight: 300;
  word-break: break-all;
}

.footer .top_part a:hover {
  color: #ffb91f;
}

.footer .bottom_part {
  padding: 15px;
  text-align: center;
}

.footer .bottom_part p {
  margin: 0;
}

.footer .bottom_part a {
  color: #686868;
}

.footer .bottom_part a:hover {
  color: #ffb91f;
}

/* Page not found */
.page_not_found {
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.page_not_found .btn {
  padding: 15px 25px;
  background-color: #ffb91f;
  color: black;
  border-radius: 20px;
  border: none;
}

.page_not_found .btn:hover {
  background-color: black;
  color: white;
  border: none;
}

/* floating contact */
.floating_contact {
  position: fixed;
  bottom: 6%;
  right: 1%;
  z-index: 99;
}

.floating_contact img {
  width: 60px;
}

/* floating shopping cart */

.phone_actions {
  display: none;
}

/* .hover_shopping_cart {
  display: none;
} */

/* loading spin */
.loading_div {
  position: fixed;
  background-color: #0000004a;
  color: white;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  backface-visibility: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20%;
}

.spinner-grow {
  position: absolute !important;
  z-index: 9;
}

/* media query */

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .nav_contact {
    display: none;
  }

  nav .d-flex {
    width: 50%;
  }

  .carousel-category-item img {
    height: 50px;
  }

  .carousel-category-item h5 {
    font-size: 8px;
    margin-bottom: 1px;
  }

  .adminPage .sidebar {
    display: none;
  }

  .toggle_siderbar {
    display: block;
  }
}

@media (max-width: 630px) {
  .popular_content .product_container {
    width: 48%;
  }
}

@media (max-width: 601px) {
  .main_carousel .carousel-item .carousel-caption {
    bottom: 7rem;
  }
}

@media (max-width: 576px) {
  .main_carousel .carousel-item .carousel-caption {
    bottom: 4rem;
  }
}

@media (max-width: 481px) {
  body {
    font-size: 14px;
  }
  .container .row {
    margin-left: 0;
    margin-right: 0;
  }
  nav .navbar-brand {
    width: 25%;
  }
  .image_overlay {
    filter: contrast(0.25);
  }

  .categories_carousel_div {
    overflow: auto;

    /* added this when changing to custom dropdown */
    white-space: normal;
    padding: 0;
  }

  .categories_carousel {
    min-height: fit-content;
  }

  .carousel-category-item {
    padding: 0 7px;
    width: 80px;
  }

  .categories_carousel_div .custom-dropdown:hover .dropdown-menu {
    display: none;
  }

  .categories_carousel_div .dropdown-menu-2 {
    display: none;
  }

  .carousel-caption h2 {
    font-size: 16px;
  }

  .carousel-caption p {
    font-size: 11px;
  }

  .main_carousel .carousel-item img {
    transform: none;
    height: 28vh;
  }

  .main_carousel .carousel-item .carousel-caption {
    bottom: 2.5rem;
  }

  .main_carousel .carousel-item .carousel-caption .btn {
    border-radius: 10px;
    padding: 7px 15px;
  }

  .popular_products .container {
    padding: 0px 5px;
  }

  .product_container .prod_name_div {
    height: 20px;
    text-overflow: ellipsis;
  }

  .product_container .prod_name_div p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .product_container h5 {
    font-size: 13px;
  }

  .popular_content .product_container {
    width: 49%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .popular_content .loading-card {
    width: 48% !important;
    height: 33vh;
    margin: 2px 2px;
  }

  .popular_content .placeholder_image {
    height: 150px;
  }

  .popular_products h2 {
    font-size: 20px;
  }

  /* .off_price {
    display: block; 
  } */

  .quick_view_spec {
    top: 10% !important;
  }

  .quick_view_spec .product_page_image img {
    width: 250px;
  }

  .popular_content .product_container img {
    width: 95%;
    height: 100%;
  }

  /* .popular_content .product_container .prod_image {
    height: 150px; 
    width: auto;
  } */

  .product_container .prod_image_container {
    height: 185px;
  }

  .star {
    width: 15px;
    height: 30px;
  }

  /* what clients say */

  .what_clients_say {
    background-position: center;
    background-attachment: fixed;
    min-height: 50vh;
    margin: 10px;
    border-radius: 10px;
    padding: 0 5%;
  }

  .what_clients_say h1 {
    font-weight: bold;
  }

  .what_clients_say_carousel {
    width: 100%;
  }

  /*  */

  .grouped_products .grouped_product_container {
    padding: 2px;
    width: 50%;
  }

  /* deals of the week */

  .deals_of_week {
    margin: 20px;
    padding: 0;
  }

  .deals_of_week .deals_header {
    margin: 10px 0;
    padding: 0;
  }

  .deals_of_week h1 {
    font-size: 14px;
  }

  .deals_of_week .deals_time {
    font-size: 15px;
    padding: 4px;
    border-radius: 5px;
  }

  .deals_of_week .deals_time .deals_time_box {
    margin: 0 2px;
  }

  .deals_of_week .deals_time .deals_time_box h2 {
    font-size: 9px;
    margin-bottom: 1px;
  }

  .deals_of_week .deals_time .deals_time_box h5 {
    font-size: 9px;
  }

  .deal_week .product_container {
    width: 100%;
    height: 315px;
    margin: 10px 0;
  }

  .deal_week .product_container img {
    width: 100%;
    height: 125%;
  }

  .deal_week .product_container .prod_name_div {
    height: auto;
  }

  .deal_week .product_container .prod_name_div p {
    font-size: 18px;
    margin-bottom: 2px;
  }

  /* best sellers */
  .best_sellers .best_seller_image {
    min-height: 35vh;
  }

  .best_sellers h3 {
    font-size: 20px;
    margin: 10px 0;
  }

  .best_seller_products {
    padding: 0;
  }

  .best_sellers .best_seller_image {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  .second_product_container {
    margin: 20px 0;
  }

  .second_product_container .second_prod_image {
    width: 200px;
  }

  .second_product_container .second_prod_image_container {
    width: 200px;
    display: flex;
    justify-content: center;
  }

  .second_prod_details {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .second_product_extended_actions_div {
    display: flex;
  }

  .second_prod_details .btn {
    font-size: 10px;
  }

  /* page filters */
  .toggleShowFilter {
    display: block;
  }

  .toggleShowFilter .btn {
    background-color: #73b38aba;
    border: none;
    color: #000;
    width: 100%;
    margin: 3px 0px;
  }

  .pageFilters {
    width: 95% !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: aliceblue;
    z-index: 99;
    margin: 0 !important;
    height: auto;
    overflow-y: auto;
    /* display: none; */
    opacity: 0;
    transform: translateX(-100%);
    transition: 1s ease;
  }

  /* .pageFilters .sub_categs_div {
    max-height: 18.5vh;
    overflow-y: scroll;
    scrollbar-width: thin;
  } */

  .showFilter {
    /* display: block; */
    opacity: 1;
    transform: translateX(0);
  }

  .closeFilter {
    display: block;
    position: absolute;
    right: 5px;
  }

  .pageProductsListing {
    padding: 2px;
    margin: 0;
  }

  .pageProductsListing .titleBar {
    display: block;
  }

  .pageProductsListing .titleBar .dropdown .btn {
    padding-left: 0;
  }

  .pProductsListing .cList .product_container {
    width: 48% !important;
    margin: 3px 2px;
    /* border: 1px solid rgb(201 201 201); */
    padding: 5px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pProductsListing .cList .product_container .prod_image {
    height: 150px;
    /* width: auto; */
  }

  .pProductsListing .cList .product_container .placeholder_image {
    height: 150px;
  }

  .pProductsListing .cList .loading-card {
    width: 48% !important;
    height: 33vh;
    margin: 2px 2px;
  }

  .product_page_more_info .nav-tabs .nav-item {
    width: 50%;
  }

  .product_page .product_page_details h1 {
    font-size: 19px;
  }

  .product_page .product_page_details h5 {
    font-size: 14px;
  }

  .product_page .product_page_details .product_page_details_buttons .btn {
    border-radius: 6px;
    font-weight: 600;
    margin: 10px 5px;
    padding: 7px 20px;
    font-size: 14px;
  }

  .product_page_more_info .nav-tabs .nav-link {
    font-size: 11px;
  }

  .product_page_more_info .tab-content > .tab-pane {
    font-size: 10px;
  }

  .custom_container {
    padding: 20px 5px;
  }

  .cart_container_alt {
    border-bottom: 1px solid gray;
  }

  .cart_container_alt .col-sm-6 {
    width: 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .cart_container_alt img {
    width: 50%;
  }

  .cart_container_alt .x_svg {
    position: absolute;
    right: 1%;
  }

  .shopping_cart_list .table {
    display: none;
  }

  .shopping_cart_list .cart_min_display {
    display: block;
    margin: 10px 0px;
    padding: 5px;
  }

  .cart_checkout {
    margin-top: 20px;
    border-radius: 0;
  }

  .phone_actions {
    display: block;
    position: sticky;
    top: 7%;
    z-index: 99;
    background-color: white;
  }

  .hovering_login_register {
    width: 100%;
    display: flex;
  }

  .hovering_login_register .col-sm-6 {
    width: 50%;
  }

  .hovering_login_register .btn {
    border-radius: 0;
    border: 1px solid white;
    width: 100%;
    font-weight: 500;
    background-color: darkcyan;
    font-size: 10px;
  }

  .hovering_login_register .dropdown-toggle::after {
    display: none;
  }

  .hovering_login_register a {
    color: white;
    display: block;
  }

  .hovering_search_bar {
    margin: 3px;
  }

  .hovering_search_bar input::placeholder {
    color: rgb(159, 159, 159);
  }

  .hovering_search_bar .input-group-text .btn {
    border: none;
    font-size: 10px;
  }

  .hover_shopping_cart {
    background-color: darkcyan;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    color: #fff;
  }

  .empty_cart {
    display: none;
  }

  .checkout_page h4 {
    font-size: 13px;
  }

  .authpage .authform {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }

  .brandcomponent {
    display: none;
  }

  .footer .top_part .col-sm-3 {
    width: 49%;
  }

  .footer .bottom_part p {
    font-size: 13px;
  }

  .footer h5 {
    font-size: 17px;
  }

  .footer .top_part a {
    font-size: 11px;
  }

  .floating_contact img {
    width: 55px;
  }
}
