.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* default css */
@font-face {
  font-family: OutfitThin;
  src: url("./assets/fonts/Outfit-Regular.woff");
}

@font-face {
  font-family: RobotoRegular;
  src: url("./assets/fonts/Roboto-Regular.woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body {
  font-weight: 400;
  /* overflow-y: hidden; */
  position: relative;
  /* background-color: #f0f0f0 !important; */
  color: #000000 !important;
  /* font-family: "Centra", sans-serif !important; */
  /* -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
  font-style: normal;
  font-weight: 400;
  font-family: RobotoRegular !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.custom-validate-class-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-validate-class-invalid {
  border-color: var(--bs-form-invalid-border-color) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.alert {
  text-align: center;
}

.alert-customizing {
  position: fixed !important;
  z-index: 9999;
  width: 100%;
  animation: fadeout 10s linear forwards;
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 0;
  margin: 0;
  z-index: 8;
  border-radius: 3px;
  /* background-color: white; */
}

/* .social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
} */
/* .social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffb91f;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
} */
.social-icon a img {
  width: 16px;
  /* width: 40%; */
  z-index: 1;
  transition: 0.3s ease-in-out;
  margin-right: 5px;
}
/* .social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
} */

.followLinks {
  text-align: left;
  color: #b2b2b2;
  padding: 16px 10px;
  background-color: #dfdfdf;
}
.followLinks a {
  color: #787878;
}
.followLinks a:hover {
  color: #ffb91f;
}

.star {
  width: 25px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.star img {
  width: 70%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.xy {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.pageFilters {
  height: fit-content;
  text-align: left;
  padding: 15px;
  width: 30% !important;
}

.pageFilters h5 {
  font-size: 1.1rem;
  margin-bottom: 20px;
  font-weight: 600;
}
.pageFilters hr {
  color: #b4b4b4;
}

.pageFilters .categLvlLinks {
  color: black;
  margin: 10px 0;
  padding: 1px 10px;
  font-weight: 400;
}

.pageFilters .categLvlLinks a:hover {
  color: #ffb91f !important;
}

.pageFilters .topLvlCategLinks {
  display: block;
  font-size: 16px;
  line-height: 1.7rem;
  color: rgb(99 98 98 / 78%);
  font-weight: 400;
}

.pageFilters .subLvlCategLinks {
  color: rgb(61, 61, 61);
  display: block;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.7rem;
  margin-left: 5%;
}

.pageProductsListing {
  margin: 8px;
  text-align: left;
  padding: 15px;
}

.pageProductsListing .titleBar {
  display: flex;
  justify-content: space-between;
}

.pageProductsListing .titleBar .dropdown .btn {
  background-color: initial;
  border: none;
  color: #848282;
}

.pProductsListing .cList .product_container {
  width: 30% !important;
  float: left;
  margin: 3px 7px;
}

/* .pProductsListing .cList .product_container .btn {
  width: 90%;
  display: none;
} */

.pProductsListing .cList .product_container:hover .btn {
  display: block;
}

.pageNumbersList {
  clear: both;
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

/* loading gif */

.loading-card {
  border-radius: 10px;
  height: 55vh;
  max-height: 100%;
  margin: 2px 7px;
  padding: 4px;
  animation: skeleton-loading 1s linear infinite alternate;
}

.featured_content .loading-card {
  height: 100%;
  width: 32%;
}

.pageProductsListing .cList .loading-card {
  width: 31%;
  float: left;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0deg 0% 82.31%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.loading-card-image {
  height: 60%;
  width: 100%;
  background-color: #80808030;
  border-radius: 10px;
}

.loading-card-content-1 {
  background-color: #80808030;
  width: 65%;
  border-radius: 10px;
  margin: 1rem 0;
}

.loading-card-content-2 {
  background-color: #80808030;
  width: 90%;
  border-radius: 10px;
  margin: 1rem 0;
}

.loading-product {
  max-height: 100%;
  width: 100%;
  margin: 2px 7px;
  padding: 4px;
  animation: skeleton-loading 1s linear infinite alternate;
}

.loading-product-image {
  height: 50vh;
  width: 100%;
  background-color: #eff0f0;
  border-radius: 10px;
}

.loading-product-content-1 {
  background-color: #eff0f0;
  width: 90%;
  margin: 1rem 0;
}

.loading-product-content-2 {
  background-color: #eff0f0;
  width: 90%;
  margin: 1rem 0;
}

.loading-product-content-3 {
  background-color: #eff0f0;
  width: 50%;
  margin: 1rem 0;
}

.loading-product-content-4 {
  background-color: #eff0f0;
  width: 50%;
  margin: 1rem 0;
}

.loading-product-btn {
  display: block;
  width: 80%;
}

.prod-spec-placeholder {
  margin: 25px 2px !important;
  min-height: 35vh;
}

.alert-danger {
  padding: 5px !important;
}

.alert-dismissible .btn-close {
  padding: 4px !important;
  font-size: 10px;
  margin: 2px;
}

.alert-heading {
  font-size: 1.3rem !important;
}

.spinner-grow {
  position: sticky !important;
  top: 35vh !important;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.input_type_hidden {
  height: 0;
  width: 0 !important;
  visibility: hidden;
  padding: 0 !important;
  margin: 0;
  float: right;
}

.no_validation_needed {
  background-image: none !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.minus-plus-container {
  display: flex;
  margin: 12px 0;
  border: 1px solid gray;
  border-radius: 8px;
  width: fit-content;
}

.minus-plus-container .btn {
  margin-top: 0;
  border-radius: 20px;
  border: none;
}

.minus-plus-container input {
  max-width: 65px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: auto;
  -moz-appearance: textfield;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

@media (max-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 934px !important;
  }
}

@media (max-width: 576px) {
  .container,
  .container-sm {
    max-width: 768px !important;
  }
}

@media (max-width: 481px) {
  .followLinks a {
    font-size: 10px;
  }
}
